import React from "react"
import SEO from "../components/seo"
import Section from "../components/Section"
import privacy from '../data/privacy.json'

const PrivacyPolicyMobile = () => {
  return (
    <main id={`page--privacy-policies-mobile`} >
      <SEO title="Privacy Policies" />
      <Section id="privacy-policy">
      <h1 className="fs-28 fs-lg-56 fs-xl-56 text-center font-bold ff-secondary text-gradient mt-1 mb-6">
          Privacy Policy
        </h1>
        <p className="text-justify text-fourth mb-3">
          We appreciate your trust in our products and services. In order to
          provide the FanClub platform through our website (myfanclub.app),
          mobile application, and through the services we provide (collectively,
          the website, application, and services referred to as our "Site"), and
          continue to make them better, FanClub LLC dba FanClub ("FanClub,"
          "we," "us," or "our") collects information from you. If you're a
          resident of California or Nevada or from the European Economic Area
          ("EEA"), you should read this policy and the applicable sections below
          for residents of{" "}
          <a
            className="text-white hover:text-white underline"
            href="/privacy-policies-mobile"
          >
            California
          </a>{" "}
          and{" "}
          <a
            className="text-white hover:text-white underline"
            href="/privacy-policies-mobile"
          >
            Nevada
          </a>{" "}
          and visitors from the the{" "}
          <a className="text-white hover:text-white underline" href="/privacy-policies-mobile">
            UK and EEA
          </a>
          . This Privacy Policy explains how FanClub collects, stores, uses,
          transfers, and discloses your information.
        </p>
        <p className="text-justify text-fourth mb-3">
          This Privacy Policy explains how FanClub collects, stores, uses,
          transfers, and discloses your information.
        </p>
        <p className="text-justify text-fourth mb-3">
          From time to time, FanClub may revise this Privacy Policy. We will
          give you notice by posting the revised Privacy Policy on our Site and
          updating the "Last Updated and Effective" date at the top of this
          Privacy Policy; we may also notify you in other ways, such as through
          the contact information you have provided or via a pop-up notification
          on our Site. Any changes to this Privacy Policy will be effective
          immediately on posting the updated Privacy Policy unless otherwise
          stated or as required by applicable law (for example, a different form
          of notice and your opt-in or opt-out consent, etc.). By continuing to
          use our Site, you agree to the revised Privacy Policy to the fullest
          extent permitted by applicable law.
        </p>
        <p className="text-justify text-fourth mb-3">
          Please also review the applicable{" "}
          <a className="text-white hover:text-white underline" href="/terms-and-conditions">
            Terms of Service
          </a>
          , which also apply to use of our Site. Terms that are defined in the
          Terms of Service have the same meaning in this Privacy Policy. You
          should read the policy in full, but here are a few key things:
        </p>

        <ul className="px-8 lg:px-16 list-disc text-fourth">
          <li>
            When you use our Site, even if you're just browsing, we receive some
            information from you, such as the type of device you’re using and
            your IP address.
          </li>
          <li>
            You can choose to share additional information with us, such as your
            email address, by creating an account, making purchases, or
            providing content to our Site.
          </li>
          <li>
            If you have questions about this policy, how we collect or process
            your personal data, or anything else related to our privacy
            practices, we want to hear from you; see{" "}
            <a className="text-white hover:text-white underline" href="mailto:support@gofanclub.com">
              Contact Us
            </a>
            .
          </li>
        </ul>

        <h1 className="fs-14 fs-lg-16 fs-xl-18 font-medium ff-secondary text-gradient mt-8 mb-4">
          Table of Contents
        </h1>

        <ul className="px-8 lg:px-16 list-decimal text-fourth">
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Scope-of-FanClub-Privacy-Policy"
            >
              Scope of FanClub Privacy Policy
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Information -We-Collect"
            >
              Information We Collect
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Cookies-and-Tracking-Technologies"
            >
              Cookies and Tracking Technologies
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#How-We-Use-Information"
            >
              How We Use Information
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#How-We-Share-Information"
            >
              How We Share Information
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Children-and-Parents"
            >
              Children and Parents
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Your-Choices"
            >
              Your Choices
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Accessing-Personal-Information-and-Retention-Period"
            >
              Accessing Personal Information and Retention Period
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Data-Security"
            >
              Data Security
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Notice-to-California-Residents"
            >
              Notice to California Residents
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Notice-to-Nevada-Residents"
            >
              Notice to Nevada Residents
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Notice-to-UK-And EEA-Users"
            >
              Notice to UK And EEA Users
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Notice-to-Users-Outside-the-U-S-UK-and-EEA"
            >
              Notice to Users Outside the U.S., UK, and EEA
            </a>
          </li>
          <li>
            <a
              className="text-white hover:text-white py-1 mb-1 inline-block"
              href="#Contact-Us"
            >
              Contact Us
            </a>
          </li>
        </ul>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Scope-of-FanClub-Privacy-Policy">
          1. Scope of FanClub Privacy Policy
        </h2>

        <p className="text-justify text-fourth mb-3">
          This Privacy Policy applies to all aspects of our Site as well as to
          information we may collect offline.
        </p>
        <p className="text-justify text-fourth mb-3">
          This Site may link to or incorporate websites or content hosted and
          served by others over which we have no control, such as third party
          websites, online properties, platforms, social media, or systems,
          which are each governed by the privacy policy and business practices
          of the third party. You understand and agree that FanClub is not
          responsible for the security, privacy, or business practices of any
          third party.
        </p>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Information -We-Collect">
          2. Information We Collect
        </h2>

        <p className="text-justify text-fourth mb-3">
          We collect information from you directly, automatically when you visit
          the Site, and sometimes from third parties. Some of this information
          may be considered "personal information" under various applicable
          laws. We consider information that identifies you as a specific,
          identified individual to be personal information (such as your name,
          telephone number, and e-mail address), as well as additional
          information, including IP addresses and cookie identifiers, where
          required by applicable law. And, if we combine information that we
          consider to be non-personal with personal information, we will treat
          the combined information as personal information under this Privacy
          Policy. Note that we may de-identify personal information so that it
          is non-personal, such as by aggregating it or converting it to a code,
          sometimes using a function known as a "hash." We will treat
          de-identified information as non-personal to the fullest extent
          permitted by applicable law.
        </p>
        <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'lower-alpha'}}>
          <li className="mb-3">
            Information You Provide
            <p className="px-4 mb-2">FanClub may collect personal information from you when you:</p>
            <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'lower-roman'}}>
              <li className="mb-1">Create an Account on, Login to, or Interact with the Site. FanClub collects your name and email address and the other information that you provide, such as a telephone number, when you register or login, update, or add information or content to your account. We require you to create a username and password if you create an account, although we may enable other methods such as logging in through one of your existing social media accounts such as Facebook, Instagram, Google, and Twitter (collectively, "Social Media Platforms") or your Apple ID. FanClub also collects certain information relating to any Social Media Platform account or Apple ID that you use to login to or link to your FanClub account (such as your username, bio, and number of followers), although linking any such account to your FanClub account or logging into FanClub via such account is entirely up to you. We may store payment information (such as credit or debit card details) but only if you choose to save it to your account. If you create an account using a FanClub provided referral link or invite code through one of our referral programs, we also receive certain information relating to your use of the unique link and invite code.</li>
              <li className="mb-1">Make a Purchase through the Site. FanClub collects the information that you provide when you request a FanClub video chat or other offering, including, for example and as applicable, your name and email address, any mobile telephone contact number (of you or the recipient) you choose to provide, the name of the recipient, and the details that you provide as part of your request. FanClub processes payment information you provide when you pay for a purchase.</li>
              <li className="mb-1">Contact Customer Service through the Site. FanClub collects the information that you provide in connection with us providing you customer service.</li>
              <li className="mb-1">Provide Content to the Site. We collect information when you submit content to us, such as a video, review, photo, email, text, social media post, or other communication (each, a "Submission") and, if you are a Creator or Brand, when you submit "Content". Each Submission, whether submitted to our Site directly or through a Social Media Platform, and all Content, is subject to the applicable Terms of Service. As a result, others may access it, use it, and share it in accordance with the Terms of Service.</li>
              <li className="mb-1">Use Social Media with the Site. We collect information when you interact with our Site through any "Social Media Platform"). For example, we may enable you to use certain of your existing Social Media Platform accounts to create an account on our Site or "like" or "share" content from our Site. Doing so may allow us to collect (or the third party to share) information about you. Some of your information, such as your username, may be publicly displayed. We may also receive information about you if other users of a Social Media Platform give us access to their profiles and you are one of their connections or "friends". In addition, we and the Social Media Platform may have access to information about you and your use of our Site and the Social Media Platform. The information we collect is subject to this Privacy Policy. The information that any third party collects is subject to its privacy practices (including whether it shares information with us, the types of information shared, and your choices about what may be visible to others).</li>
              <li className="mb-1">Use the Site as a Creator or Brand. When you use our Site to become or as a Creator or Brand, we collect, in addition to information provided when you create your account, your name, email address, telephone number, and the social media usernames you provide, FanClub Videos and other offerings prepared by you, and other information relating to our relationship with you, such as payment information. FanClub’s payments to you are made through the third party payment provider selected by FanClub. You should review the payment provider’s privacy policy before providing your personal information, as it is responsible for personal information (as a data controller if you’re in the UK or EEA) in its own right, and its privacy practices may vary from ours.</li>
              <li className="mb-1">Apply for a Job with FanClub. We collect the information you provide when you apply for a job with us (for example, through the "Jobs" link on our Site) or directly; this may include your name, contact information, employment history, educational background, any self-identifying information (such as veteran status, gender, and ethnicity) and other information you provide. Please note that your use of a third party tool or third-party applicant tracking system utilized by FanClub (e.g., LinkedIn, Indeed, etc.) to apply is subject to the third party’s privacy policy.</li>
            </ol>
          </li>
          <li className="mb-3">
            Information from Third Parties
            <p className="px-4 mb-4">We may also obtain information, such as contact details (for example, name and email address), country, IP address, purchase histories, marketing preferences, cookie information, hardware and software information and other technical information about you from third parties, such as other users of the Site who upload information or sync their contacts, analytics companies, Social Media Platforms, and other third party business partners.</p>
            <p className="px-4 mb-4">We and our third party business partners may collect information about your visits to and activity on our Site and other websites and services. We may combine that information with other personal information we have collected from or about you. We may share certain of this information with third party business partners for similar purposes.</p>
          </li>
        </ol>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Cookies-and-Tracking-Technologies">
          3. Cookies and Tracking Technologies
        </h2>

        <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'lower-alpha'}}>
          <li className="mb-3">
            Cookies and Tracking
            <p className="px-4 mb-2">When you visit our Site or otherwise interact with us or our content, we and third-party advertising and analytics companies and other third party business partners may use a variety of tracking and other technologies that automatically or passively collect certain information ("Usage Information").</p>
            <p className="px-4 mb-2">We may use these technologies for a number of reasons, including to save your preferences for future visits, to our Site, keep you logged in between visits, to serve targeted advertising to you, and to provide you with improved services. The information we collect may include (but is not limited to):</p>
            <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'decimal'}}>
              <li className="mb-1">date and time of your visit to our Site;</li>
              <li className="mb-1">areas you visit within our Site;</li>
              <li className="mb-1">links that you click on within our Site;</li>
              <li className="mb-1">websites or advertisements you visit before or after visiting our Site;</li>
              <li className="mb-1">terms you entered into a search engine that lead you to our Site;</li>
              <li className="mb-1">IP address, mobile device identifier, or other unique identifier and usage information for the device used to access our Site;</li>
              <li className="mb-1">precise location data from your device, if you have opted-in to the collection of that information (you may be able to disable the collection of precise location data through the settings on the device used to access our Site, but your approximate location may remain available through its IP address or other information that we collect);</li>
              <li className="mb-1">device and connection information, such as browser type and version, operating system, and platform; and</li>
              <li className="mb-1">whether an email message we sent was opened and whether a link in the email message was clicked.</li>
            </ol>
          </li>

          <li className="mb-3">
            Methods We Use to Collect
            <p className="px-4 mb-2">The methods that may be used to collect Usage Information include the following:</p>
            <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'decimal'}}>
              <li>Cookies and Local Storage. A cookie is a data file placed on a device when it is used to visit the Site or otherwise access our content. Certain web browsers and certain browser add-ons, such as Adobe Flash, may provide additional local data storage mechanisms that are used in a manner similar to cookies, and some of the content included on our Site may make use of this local storage. To get information about Flash and Adobe’s privacy choices from Adobe, <a className="text-white hover:text-white underline" href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html" target="_blank" rel="noopener">click here</a>. To get information about cookies from All About Cookies.org, <a className="text-white hover:text-white underline" href="https://www.allaboutcookies.org" target="_blank" rel="noopener">click here</a>. Cookies, Flash cookies, and other local storage devices may be used for a variety of purposes (for example, to identify your device when you revisit our Site, to recall your authentication information, to store information related to your navigation throughout our Site, or to enable certain features on our Site). Some cookies are essential to the functioning of the Site, and, without them, some features and services you have requested cannot be provided. If you disable cookies, adjust privacy settings on your device, restrict local storage, or otherwise limit these technologies, you may not be able to use some of our Site features.</li>
              <li>Embedded Scripts. An embedded script is a programming code that is designed to collect information about your interactions with the Site, such as the links you click on. The code is temporarily downloaded onto your device from our server or a third party service provider, is active only while you are connected to the Site, and is deactivated or deleted after you are no longer connected.</li>
              <li>Other Technologies. We may also use other technologies that collect similar information for security and fraud detection purposes.</li>
            </ol>
          </li>

          <li className="mb-3">
            "Do Not Track" Signals
            <p className="px-4 mb-2">Some Internet browsers may be configured to send "Do Not Track" signals to the online services that you visit. There is no consensus among industry participants as to what "Do Not Track" means in this context. Like many websites and online services, FanClub does not currently alter our practices when we receive a "Do Not Track" signal from a visitor’s browser except as specifically required by law. For information about "do not track" please visit <a className="text-white hover:text-white underline" href="https://allaboutdnt.com" target="_blank" rel="noopener">The Future of Privacy Forum</a>.</p>
          </li>
        </ol>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="How-We-Use-Information">
          4. How We Use Information
        </h2>

        <p className="text-justify text-fourth mb-3">We may combine information that we have received from you with information from other sources to operate, tailor content, personalize, adjust, and improve our Site. We use your information:</p>
        <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'decimal'}}>
          <li className="mb-1">to fulfill your request for a FanClub video chat with a Creator or other offering, accept your payment, deliver email messages, and process information you provide through our Site;</li>
          <li className="mb-1">to maintain your account, to assist with payment transactions, to facilitate use of the Site, and to communicate with you about your account, our relationship with you, and your use of the Site, such as by verifying your information, requesting that you respond to a survey, or checking on the status of an order or request. We may send you text messages if you have provided us with your mobile telephone number for that purpose;</li>
          <li className="mb-1">to establish and manage a relationship with you as a Creator or Brand User;</li>
          <li className="mb-1">to send you promotional email or text message communications from FanClub (not from any third party) if you have opted in to receive them. To stop receiving email messages, click on the "unsubscribe" link in the footer of the promotional email; please note that even if you unsubscribe, we may send you communications relating to your account or use of the Site, such as administrative and services announcements;</li>
          <li className="mb-1">to conduct and administer a Promotion if you have chosen to participate. We will not share your information with a third party for the third party’s marketing or promotional purposes unless you agree when you choose to participate in the Promotion;</li>
          <li className="mb-1">to provide you and others with customer service, to assist in resolving issues, and to evaluate and improve our customer service and processes;</li>
          <li className="mb-1">to use any Submission or Creator Content as set forth in the applicable <a className="text-white hover:text-white underline" href="/terms-and-conditions">Terms of Service</a>, which may include the public display of some information, such as your name, username, pronouns, or where you reside;</li>
          <li className="mb-1">to consider your job application and, in connection with the application, to fulfill legal reporting requirements, for internal business purposes (such as to evaluating and improving our recruiting processes), or to defend against employment-related claims;</li>
          <li className="mb-1">to contact you about administrative matters, such as the applicable <a className="text-white hover:text-white underline" href="/terms-and-conditions">Terms of Service</a>, or other issues;</li>
          <li className="mb-1">to better understand your personal preferences and to enable us to provide you with improved and personalized communications and services;</li>
          <li className="mb-1">to compile aggregate data about Site traffic and interaction;</li>
          <li className="mb-1">to tailor the content and advertising we display to you or others, on our Site or elsewhere, and to analyze trends and statistics;</li>
          <li className="mb-1">to create advertising models through lookalike modeling or other research methodologies;</li>
          <li className="mb-1">for internal business purposes, such as improving our Site, products, and services and to comply with legal requirements and our business practices, such as our recordkeeping, backup, and document retention policies; and</li>
          <li className="mb-1">for other reasons that (a) we disclose when asking you to provide your information, (b) with your consent, or (c) as described in this Privacy Policy.</li>
        </ol>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="How-We-Share-Information">
          5. How We Share Information
        </h2>

        <p className="text-justify text-fourth mb-3">We share non-personal information, such as aggregated statistics and de-identified information, with third parties to the extent permitted by applicable law. FanClub also shares personal information as provided below in more detail. We do not share personal information with any third party to use for its own marketing or promotional purposes without your consent.</p>
        <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'lower-alpha'}}>
          <li className="mb-1">
            <p>Our Affiliates</p>
            <p>We share information with our affiliates, as relevant, who use it for the same purposes for which we use it. For information about FanClub affiliates.‍</p>
          </li>
          <li className="mb-1">
            <p>Third Parties Providing Services on our Behalf</p>
            <p>We use third parties to perform certain services on our behalf, such as hosting the Site or Site features, providing website usage analytics, fulfilling orders, processing payments, sending email or other communications, providing marketing assistance and data analysis or enhancement, running Promotions, or performing other administrative services. We may share information about you with these third parties to allow them to execute, deliver, or improve the services that they provide for you and for FanClub.</p>
          </li>
          <li className="mb-1">
            <p>Employment Applications</p>
            <p>If you apply for a job with FanClub or any of our subsidiaries or affiliates, we or our service providers may ask you to provide self-identifying information (such as veteran status, gender, and ethnicity) in conjunction with laws and regulations enforced by the U.S. Equal Employment Opportunity Commission and other regulatory agencies. Providing such self-identifying information is voluntary, but if you do provide such information, we and our service providers may submit that information to the appropriate government or regulatory agencies to fulfill reporting requirements and use that information to defend against employment-related complaints.‍</p>
          </li>
          <li className="mb-1">
            <p>When You Request That We Share Your Information</p>
            <p>We may share information when you request us to do so, for example, if you opt-in to receive a communication from a third party or if you engage with a Social Media Platform or other third party application or feature through which information about you is shared.</p>
          </li>
          <li className="mb-1">
            <p>Administrative Reasons, To Enforce and Protect Legal Rights</p>
            <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'decimal'}}>
              <li>To the extent permitted by applicable law, we will disclose your personal information pursuant to a law, regulation, court order, or other legal request or process, such as disclosure to law enforcement or other government officials or agencies in connection with any investigation, for example of fraud, intellectual property infringement, or any other activity that is or may be illegal or may expose FanClub, you, or another to legal liability. This disclosure may include legal requests from a jurisdiction outside of the United States when we have a good faith belief that the response is required by law, regulation, court order, or other legal request or process in that jurisdiction, affects users in that jurisdiction, and is consistent with internationally-recognized standards.</li>
              <li>We will disclose your personal information when necessary to exercise, enforce, establish, or defend our legal rights. For example, we may review your account information in order to investigate allegations of hacking or a breach of the applicable <a className="text-white hover:text-white underline" href="/terms-and-conditions">Terms of Service</a>. We will disclose your personal information to our legal and other advisors, consultants, and law enforcement or other government entities.</li>
            </ol>
          </li>
          <li className="mb-1">
            <p>‍Protecting Others</p>
            <p>We will disclose your personal information to third parties when we believe the disclosure is necessary to protect the rights, property, or safety of another. For example, we disclose personal information relating to a Site account if we in good faith believe that a Site account is being used in ways that are harmful to another or contrary to the applicable <a className="text-white hover:text-white underline" href="/terms-and-conditions">Terms of Service</a>.</p>
          </li>
          <li className="mb-1">
            <p>Business Transfers</p>
            <p>In evaluating or engaging in a sale of assets, merger, acquisition, reorganization, bankruptcy, or other transaction, we reserve the right to disclose, transfer, or assign your personal information and communications. By providing your personal information, you agree that we may transfer such information to the acquiring entity without your further consent.</p>
          </li>
        </ol>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Children-and-Parents">
          6. Children and Parents
        </h2>

        <p className="text-justify text-fourth mb-3">Our Site is intended for a general audience and not for use by anyone younger than the age of 13 in the United States (or under the applicable legal age outside of the United States). We do not knowingly collect personal information from children younger than the age of 13 (or under the applicable legal age outside of the United States), without the consent of a parent or legal guardian; if we learn that we have done so, we will promptly remove the information from our active databases. If you are a parent or legal guardian who believes that we have collected or used your child’s personal information without your consent, please contact us; see <a className="text-white hover:text-white underline" href="mailto:support@gofanclub.com">Contact Us</a>.</p>
      
        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Your-Choices">
          7. Your Choices
        </h2>
        
        <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'lower-alpha'}}>
          <li>
            <p>‍Cookie and Marketing Preferences</p>
            <p>To stop receiving promotional email communications from FanClub, click on the "unsubscribe" link in the footer of the promotional email. As noted above, we may send you email communications relating to your account or use of the Site even if you have unsubscribed.</p>
          </li>
          <li>
            <p>‍Third Party Advertising and Analytics Companies and Your Choices</p>
            <p className="mb-2">FanClub works with third party network advertisers, ad agencies, and other advertising partners to serve FanClub ads online. We may use information we have learned about you to target ads to you and to allow third parties to target ads to you. We also use third party analytics providers to provide us with information regarding the use of the Site and the effectiveness of our advertisements. These third parties may set and access their own tracking technologies on your device (including cookies and web beacons), and they may otherwise collect or have access to information about you, including Usage Information. We and our third party advertising and analytics providers may collect personal information over time when you visit the Site or other online websites and services.</p>
            <p>
              We may share non-personal information, including information that has been de-identified, aggregated user statistics, and Usage Information, directly or indirectly through third party business providers, with third party advertising companies, analytics providers, and other third parties, including for the purpose of serving you more relevant ads. We may retain third party providers to help us perform these tasks. For example, we may share personal information with third parties who combine and link information from FanClub with information from other sources. These third party providers may then make non-personal information available to third party advertisers, including information about your interests and preferences, to enable them to serve you more relevant ads.
              <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'decimal'}}>
                <li className="mb-1">Some of these third parties may be members of the Network Advertising Initiative ("NAI") or Digital Advertising Alliance ("DAA") Self-Regulatory Program for Online Behavioral Advertising. NAI and DAA each provide information regarding procedures for opting-out of targeted online advertising from participating companies. For information from NAI about opting out, <a className="text-white hover:text-white underline" href="http://optout.networkadvertising.org/?c=1" target="_blank" rel="noopener">click here</a>. For information from DAA about opting out, <a className="text-white hover:text-white underline" href="http://www.aboutads.info/consumers" target="_blank" rel="noopener">click here</a>, and for information about DAA’s AppChoices application, <a className="text-white hover:text-white underline" href="https://youradchoices.com/appchoices" target="_blank" rel="noopener">click here</a>.</li>
                <li className="mb-1">For mobile applications, you may be able to limit certain advertising practices using the settings on your mobile telephone, tablet, or other mobile device. On most mobile devices, you can install the DAA’s AppChoices app to make choices about cross-app interest-based advertisements from participating third parties. For information from NAI about opting out on mobile devices, <a className="text-white hover:text-white underline" href="https://www.networkadvertising.org/mobile-choice/" target="_blank" rel="noopener">click here</a>.</li>
                <li className="mb-1">FanClub uses Google Analytics. Google Analytics uses cookies and similar technologies to collect and analyze information about the use of the Site and report on activities and trends. This service may also collect information regarding the use of other websites, apps, and online resources. For information from Google about opting out, <a className="text-white hover:text-white underline" href="https://support.google.com/ads/answer/2662922" target="_blank" rel="noopener">click here</a>, and to download the Google Analytics opt-out browser add-on from Google, <a className="text-white hover:text-white underline" href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener">click here</a>.</li>
              </ol>
            </p>
          </li>
        </ol>
        <p className="text-justify text-fourth mb-3">If you are a California resident, please see our <a className="text-white hover:text-white underline" href="https://legal.cameo.com/privacypolicy#notice-california" target="_blank" rel="noopener">Notice to California Residents</a> regarding additional rights you have, including how to exercise your "Do Not Sell" right.
Opting out through these mechanisms does not block all online advertising; you will continue to receive generic advertisements.</p>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Accessing-Personal-Information-and-Retention-Period">
          8. Accessing Personal Information and Retention Period
        </h2>
        
        <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'lower-alpha'}}>
          <li>
            <p>Accessing Your Account</p>
            <p>Please note that if you choose not to provide us with certain personal information, we may not always be able to provide you with the relevant FanClub product or service that you request. For example, without providing an email address, you will not be able to create a Site account.</p>
            <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'decimal'}}>
              <li className="mb-1">If you have a Site Account, you may access, view, correct, or update certain personal information collected about you, as well as deactivate or delete the account through the account services portal on myfanclub.app or in the mobile application. Users may also deactivate their account by clicking “Delete your account” under Settings.</li>
              <li className="mb-1">If you have any concerns about how we are using your personal information, would like to access, correct, or delete the personal information that you have provided to us, please contact us; see <a className="text-white hover:text-white underline" href="mailto:support@gofanclub.com">Contact Us</a>.</li>
            </ol>
          </li>
          <li>
            <p>‍Retention Period</p>
            <p>When you request that we delete your personal information, we will take commercially reasonable steps to remove it from our active databases but will keep original information consistent with our business practices to the extent permitted by applicable law (for example, for purposes of dispute resolution, enforcement of agreements, complying with legal requirements, recordkeeping, backup, and document retention).</p>
          </li>
        </ol>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Data-Security">
          9. Data Security
        </h2>

        <p className="text-justify text-fourth mb-3">FanClub takes commercially reasonable security measures to protect the information submitted to us. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure and you use our Site and transmit information to us at your own risk. If you have any questions about security on our Site, please contact us; see <a className="text-white hover:text-white underline" href="mailto:support@gofanclub.com">Contact Us</a>.</p>
      
        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Notice-to-California-Residents">
          10. Notice to California Residents
        </h2>

        <p className="text-justify text-fourth mb-3">After January 1, 2020, Users or Site visitors residing in California may be afforded certain rights regarding their personal information. Except where an exception or exemption applies, these rights may include the ability to request disclosure of the information we have collected about you, the right to review our data collection practices related to you, the ability to request deletion of your personal information, and the right not to be discriminated against due to your exercise of any of these privacy rights. While these rights are not globally applicable, all of our customers are entitled to request access to the personal information that we hold about them. To ensure that your personal information is accurate and complete, please contact us through the email address below regarding any errors or updates affecting the personal information that we may hold.</p>
        <p className="text-justify text-fourth mb-3">You may also send us an email at support@goFanClub.com to request access to, correct, or delete any personal information that we hold about you. Residents of California may designate an authorized agent to exercise certain privacy rights on their behalf, and should make any such request by contacting the above email address. To protect your privacy and security, FanClub may take reasonable steps to verify your identity before granting access to, correcting, or deleting data. We will generally respond to your request for information within 45 days, unless a longer response time is necessary, in which case, you will be advised accordingly. Please note that we may retain certain information as required by law or as necessary for our legitimate business purposes.</p>
      
        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Notice-to-Nevada-Residents">
          11. Notice to Nevada Residents
        </h2>

        <p className="text-justify text-fourth mb-3">FanClub does not currently sell your covered information as defined under applicable Nevada law; however, you may submit an opt-out request, which we will honor as required by Nevada law should engage in such a sale in the future.</p>
        <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'lower-alpha'}}>
          <li className="mb-2">
            <p>Making a Request</p>
            <p>You may submit an opt-out request by sending an email message to support@goFanClub.com; along with your full name, any username, and complete mailing address (including street address, city, state, and zip code), email address (so that we can contact you, if needed, in connection with the request) and confirmation that you are a Nevada resident.</p>
          </li>
          <li>
            <p>Verifying a Request</p>
            <p>You must provide us with information reasonably requested by us to verify your identity and the authenticity of the request.</p>
            <p className="mt-2">We typically process these requests within 60 days from the day we receive it (and will notify you within that period if we need an additional 30 days to do so).</p>
          </li>
        </ol>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Notice-to-UK-And EEA-Users">
          12. Notice to UK And EEA Users
        </h2>

        <p className="text-justify text-fourth mb-3">For privacy-related information applicable to people located in the UK or EEA:
FanClub is the data controller for the purposes of the General Data Protection Regulation (EU) 2016/679 ("GDPR"); and "personal information" as used in this Privacy Policy is "personal data," as defined in Article 4(1) of the GDPR.</p>
        
        <ul className="px-8 lg:px-16 list-disc text-fourth">
          <li>apply for a job with FanClub;</li>
          <li>discuss participating or participate on the Site as a Creator User via FanClub</li>
        </ul>

        <p className="text-justify text-fourth mb-3"></p>
        <p className="text-justify text-fourth mb-3">If you have any questions about how we use your personal data, or to exercise your data protection rights please contact us; see <a className="text-white hover:text-white underline" href="mailto:support@gofanclub.com">Contact Us</a>.</p>
        <p className="text-justify text-fourth mb-3">The scope of this notice applies to use of your personal information by FanClub, whether through the Site or otherwise. We process your information for the purposes set out below.
More information about FanClub can be found <a className="text-white hover:text-white underline" href="/terms-and-conditions">here</a>.</p>

        <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'lower-alpha'}}>
          <li>
            <p>Legal Basis for Processing Information</p>
            <p>FanClub processes your information where there is a legal ground to do so, as described below in Sections 12.a(i) through 12.a(iv).</p>
            <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'lower-roman'}}>
              <li>
                <p>Performance of the Services. We process your information for the performance of our Site, to provide or support our products and services, or for any other reason you request or enable. This includes, for example, using your information to:</p>
                <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'upper-alpha'}}>
                  <li>administer your Site account;</li>
                  <li>facilitate your participation on the Site as a Creator User, or prospective Creator User (which includes sending your information to the United States);</li>
                  <li>conduct and administer any sweepstakes, contest, or promotion you have entered;</li>
                  <li>support purchases you make, including, but not limited to, processing payments and orders;</li>
                  <li>support Site functionality;</li>
                  <li>maintain our Site in accordance with this Privacy Policy and the applicable <a className="text-white hover:text-white underline" href="/terms-and-conditions">Terms of Service</a>;</li>
                  <li>provide customer service; and</li>
                  <li>if you are a Creator User of our Site, to assist you in connection with your participation in our platform, offering and providing FanClub video chats and other offerings, and in connection with payments from our third-party payment provider, as specified in the <a className="text-white hover:text-white underline" href="/terms-and-conditions">Creator Terms of Service</a>.</li>
                </ol>
              </li>
              <li>
                <p>Consent. We process your information based on your consent where consent is the lawful basis for such processing. This processing includes FanClub providing you with:</p>
                <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'upper-alpha'}}>
                  <li>newsletters, direct e-mails and surveys about our Site; and</li>
                  <li>
                    <p>certain other marketing features.</p>
                    <p>Where FanClub relies on your consent as the lawful basis for processing your personal information, you have the right to withdraw your consent to further use of your personal information at any time. You can use the methods described in the <a className="text-white hover:text-white underline" href="/privacy-policies-mobile">Accessing Personal Information and Retention Period</a> to update your marketing preferences.</p>
                  </li>
                </ol>
                <p>You may request more information regarding our legitimate interest balancing test where this is our lawful basis by sending an email message to support@gofanclub.comwith your request and confirmation that you are in the UK or EEA.</p>
              </li>
              <li>
                <p>Legitimate Interest. To process your information when FanClub has a legitimate interest to do so. This includes, for example, processing your information to:</p>
                <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'upper-alpha'}}>
                  <li>provide you with requested customer service or technical support;</li>
                  <li>debug and improve the current and future Site;</li>
                  <li>provide you with certain content, personalize your Site experience with us and contact you in accordance with your applicable marketing preferences;</li>
                  <li>monitor activity on the Site to prevent or detect any fraudulent use of the Site;</li>
                  <li>prevent or detect any fraudulent payment; and</li>
                  <li>
                    establish, exercise, or defend legal claims or in connection with any court or jurisdiction.
                    <p>You may request more information regarding our legitimate interest balancing test where this is our lawful basis by sending an email message to support@gofanclub.comwith your request and confirmation that you are in the UK or EEA.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>Legal Obligation. To process your information for compliance with any legal obligation to which FanClub is subject.</p>
                <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'lower-alpha'}}>
                  <li>
                    <p>Your Rights to Your Information</p>
                    <p>In addition to your rights to view, correct, and update your information as described in <a className="text-white hover:text-white underline" href="/privacy-policies-mobile">Accessing Personal Information and Retention Period</a>, you may submit a request to access or remove your personal information, by sending an email message to support@goFanClub.com with your request and confirmation that you are located in the UK or EEA. In addition, if you have a Site account, you may submit a request to remove your personal information through the account services portal on FanClub.com or the mobile application.</p>
                    <p className="mt-3">In certain circumstances, you can request that we transfer personal information that you have provided to us by sending an email message to <a className="text-white hover:text-white underline" href="mailto:support@gofanclub.com">support@goFanClub.com</a> with your request and confirmation that you are located in the UK or EEA.</p>
                  </li>
                  <li>
                    <p>Your Right to Object</p>
                    <p>You may object to the processing of your personal information based on a legitimate interest on grounds relating to your circumstances. You may, in particular, control the extent to which FanClub markets to you and you have the right to request that we stop sending you marketing messages at any time. To do so, you can use the methods described in <a className="text-white hover:text-white underline" href="/privacy-policies-mobile">Accessing Personal Information and Retention Period</a>.</p>
                    <p className="mt-3">Your right to file complaints with a data protection supervisory authority in the country where you live or work or where you consider a breach has occurred remains unaffected.</p>
                  </li>
                  <li>
                    <p>International Transfers</p>
                    <p>Your information will be collected, processed, and stored directly on or transferred to servers in the U.S. In addition, it may be transferred to other countries where FanClub or other FanClub affiliates, or third party business partners maintain facilities or business operations, which may include countries outside the UK or EEA. Where we are required by law to implement appropriate safeguards to protect your personal information, we use European Commission-approved contract clauses to do so. If you have questions, please contact us; see <a className="text-white hover:text-white underline" href="mailto:support@gofanclub.com">Contact Us</a>.</p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>

        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Notice-to-Users-Outside-the-U-S-UK-and-EEA">
          13. Notice to Users Outside the U.S., UK, and EEA
        </h2>

        <p className="text-justify text-fourth mb-3">As noted in <a className="text-white hover:text-white underline" href="/privacy-policies-mobile">International Transfers</a>, your information will be collected, processed, and stored directly on or transferred to servers in the U.S. and may be transferred other countries where FanClub, FanClub affiliates or our third party business partners maintain facilities or business operations. Your personal information will be accessible to law enforcement and/or regulatory authorities according to the applicable laws of such countries.
No matter where the information is located, FanClub takes commercially reasonable measures to safeguard your privacy rights in accordance with this Privacy Policy. If you do not agree to the collection, processing, storage and transfer of your information, please do not provide your information to us and stop using our Site.</p>
      
        <h2 className="fs-14 fs-lg-24 fs-xl-24 font-medium ff-secondary text-gradient mt-8 mb-4" id="Contact-Us">
          14. Contact Us
        </h2>

        <ol className="px-8 lg:px-16 text-fourth" style={{listStyleType: 'lower-alpha'}}>
          <li>
            <p>To contact us about this Privacy Policy only:</p>
            <ol className="px-4 lg:px-8 text-fourth" style={{listStyleType: 'lower-roman'}}>
              <li>send an email to <a className="text-white hover:text-white underline" href="mailto:support@gofanclub.com">support@goFanClub.com</a>;</li>
              <li>
                <p>write to us at:</p>
                <p className="mt-3">
                  <p>FanClub LLC d/b/a FanClub</p>
                  <p>3441 N Sheffield Avenue,</p>
                  <p>Chicago, IL 60657: Data Protection</p>
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>To contact us on other matters:</p>
            <p>write to us at:</p>
              <p className="mt-3">
                <p>FanClub LLC d/b/a FanClub</p>
                <p>3441 N Sheffield Avenue,</p>
                <p>Chicago, IL 60657</p>
              </p>
          </li>
        </ol>     
      </Section>
    </main>
  )
}

export default PrivacyPolicyMobile
